{
  const gtmInit = (): void => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', 'dataLayer', 'GTM-5WTJ29G'));
  };

  const gtagInit = () => {
    const s = document.createElement('script');
    s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-158665768-1';
    s.async = true;
    document.body.appendChild(s);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      dataLayer.push(arguments);
    };
    gtag('js', new Date());
    gtag('config', 'UA-158665768-1');
  };

  // const twitterInit = (): void => {
  //   window.twttr = (function (d, s, id) {
  //     let js;
  //     const fjs = d.getElementsByTagName(s)[0];
  //     const t = window.twttr || {};
  //     if (d.getElementById(id)) return t;
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = 'https://platform.twitter.com/widgets.js';
  //     fjs.parentNode.insertBefore(js, fjs);
  //     t._e = [];
  //     t.ready = function (f) {
  //       t._e.push(f);
  //     };
  //     return t;
  //   }(document, 'script', 'twitter-wjs'));
  // };

  // const facebookInit = () => {
  //   const s = document.createElement('script');
  //   s.src = 'https://connect.facebook.net/en_US/sdk.js';
  //   s.async = true;
  //   s.defer = true;
  //   document.body.appendChild(s);
  // };

  // const instagramInit = () => {
  //   const s = document.createElement('script');
  //   s.src = 'https://platform.instagram.com/en_US/embeds.js';
  //   s.async = true;
  //   s.defer = true;
  //   document.body.appendChild(s);
  // };

  const yandexInit = () => {
    (function (m, e, t, r, i, k, a) {
      m[i] = m[i]
        || function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      (k = e.createElement(t)),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = 1),
      (k.src = r),
      a.parentNode.insertBefore(k, a);
    }(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'));

    ym(67715335, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  };

  // const mailRuInit = () => {
  //   const _tmr = window._tmr || (window._tmr = []);
  //   _tmr.push({ id: '3213079', type: 'pageView', start: new Date().getTime() });
  //   (function (d, w, id) {
  //     if (d.getElementById(id)) return;
  //     const ts = d.createElement('script');
  //     ts.type = 'text/javascript';
  //     ts.async = true;
  //     ts.id = id;
  //     ts.src = 'https://top-fwz1.mail.ru/js/code.js';
  //     const f = function () {
  //       const s = d.getElementsByTagName('script')[0];
  //       s.parentNode.insertBefore(ts, s);
  //     };
  //     if (w.opera == '[object Opera]') {
  //       d.addEventListener('DOMContentLoaded', f, false);
  //     } else {
  //       f();
  //     }
  //   }(document, window, 'topmailru-code'));
  // };

  if (typeof window !== 'undefined') {

  setTimeout(async () => {
    gtmInit();
  }, 500);
  setTimeout(async () => {
    gtagInit();
  }, 550);
  setTimeout(async () => {
    yandexInit();
  }, 400);
  }
}
